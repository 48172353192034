.div-content{
  display: flex;
  /* background-color: rgb(1, 20, 82); */
  /* background-color: #1D212B; */
  /* background-image: url(../../../assets/images/back-search.jpg); */
  /* background: linear-gradient(to bottom, #2a2a2a, #00688D, #ffffff); */ 
  background-size: cover;
  /* background-color: linear-gradient(to bottom,#1e5799 0%,#d3dde5 100%); */
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 300px;
  flex-direction: column;
  padding-top: 30px;
  padding-bottom: 30px;
}


.div-title-content {
  /* background-color: #1D212B; */
  display: flex;
  height: 30%;
  width: 70%;
  justify-content: center;
  align-items: center;
  z-index: 3;
}

.title-content {
  /* background-color: #1D212B; */
  display: flex;
  color: aliceblue;
  font-family: 'Nunito-Bold';
  font-size: 28px;
  /* margin-bottom: 30px; */
  text-transform: uppercase;
  text-align: center;
}

.div-inputs-search-switch-trip-type {
  /* background-color: #13b819; */
  display: flex;
  width: 100%;
  position: absolute;
  top: -45px;
}

.div-inputs-search-hosting-new-tab {
  /* background-color: #13b819; */
  display: flex;
  width: 270px;
  position: absolute;
  flex-direction: row;
  align-items: center;
  top: -38px;
}

.text-hosting-new-tab {
  display: flex;
  font-family: 'Nunito-Regular';
  font-size: 13px;
  color: #F2F2F2;
  margin-left: 10px;
}


.div-inputs-search{
  /* background-color: #13b819; */
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  height:  fit-content;
  min-width: auto;
  flex-direction: row;
  margin-top: 60px;
}



.div-inputs-places-quantify {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 35%;
  min-width: 350px;
  height: 70px;
  border-right: 2px solid rgba(0,104,141,1) ; 
  /* min-width: 500px; */
}

.div-inputs-places {
  background-color: #ffffff;
  /* flex: 1; */
  display: flex;
  flex-direction: column;
  border-radius: 0px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  justify-content: center;
  width: 100%;
  height: 70px;
  flex-direction: row;
}
.div-inputs-selector{
  /* background-color: #13b819; */
  display: flex;
  width: 70%;
  /* justify-content: space-between; */
  align-items: center;
  height:  50px;
  min-width: 990px;
  margin-top: 10px;
  /* border: 1px solid #ffffff; */
}

.div-quantify {
  /* background-color: #13b819; */
  display: flex;
  flex-direction: row;
  width: 98%;
  align-items: center;
  height:  auto;
  justify-content: space-between;
  border: 1px solid #ffffff;  
  margin-top: 5px;
  border-radius: 5px;
  align-items: center;
}

.div-adults-quantify{
  /* background-color: #0e1572; */
  display: flex;
  width: 30%;
  /* justify-content: space-between; */
  align-items: center;
  height:  auto;
  /* border: 1px solid #ffffff;  */
  justify-content: center; 
  border-radius: 7px;
  /* margin-right: 10px; */
  /* margin-top: 10px; */
}

.text-adults-quantify {
  color: rgb(244, 244, 244);
  font-size: 14px;
  font-family: 'Nunito-Bold';
  margin-left: 5px;  
  margin-right: 5px;
}

/*REMOVE*/
/* .div-inputs-date-type-trip {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 40%;
  justify-content: space-between;
  align-items: center;
  height: auto;
} */

.div-inputs-date {
  position: relative;
  /* background-color: #13b819; */
  display: flex;
  width: calc(fit-content);
  min-width: fit-content;
  justify-content: space-between;
  align-items: center;
  height: 70px;
}

.div-switch-trip-type {
  /* background-color: #13b819; */
  display: flex;
  flex-direction: row;
  width: 98%;
  padding-right: 9px; 
  align-items: center;
  height:  auto;
  justify-content: space-between;
  /* border: 1px solid #ffffff;   */
  margin-top: 5px;
  align-items: center;
}

.div-search-button{
  height: 70px;
  width: auto;
  margin-left: 10px;
  z-index: 3;
}


.div-background-footer-modal {
  background-color: #ffffff;
  display: flex;
  width: 86%;
  padding-bottom: 10px;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 105%; 
  left: -5px;
  border-radius: 10px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  overflow: hidden;
  z-index: 1000;
}

.hidden-calendar {
  display: none;
}

.div-switch-trip-passengers {
  /* background-color: #f20000; */
  display: flex;
  width: 90%;
  min-width: 448px;
  height: auto;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 0px;
  margin-bottom: 0px;
}

.div-switch-trip {
  display: flex;
  width: 200px;
}

.div-passengers {
  background-color: rgb(255, 255, 255);
  display: flex;
  position: relative;
  width: 80px;
  height: 70px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-left: 2px solid rgba(0,104,141,1);
  cursor: pointer;
  /* border-top: 1px solid rgb(255, 255, 255);
  border-right: 1px solid rgb(255, 255, 255);
  border-bottom: 1px solid rgb(255, 255, 255); */
}


.div-inputs-places-separator {
  display: flex;
  background-color: rgba(0, 104, 141, 1);
  width: 2px;
  height: 100%;
}


.div-content-footer-passengers-selector-modal{
  /* background-color: #1e5799; */
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  flex-direction: column;
  padding-top: 30px;
  padding-bottom: 20px;
  user-select: none;
}

.div-passengers-item-selector-modal{
  /* background-color: #68991e; */
  display: flex;
  flex-direction: row;
  width: 85%;
  height: fit-content;
  justify-content: space-between;
  margin-top: 0px;
  margin-bottom: 20px;
}

.div-label-passengers-item-selector-modal{
  /* background-color: #1e5799; */
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: space-between;
}

.tille-passengers-item-selector-modal{
  color: #838383;
  font-size: 17px;
  font-family: 'Nunito-Bold';
}

.description-passengers-item-selector-modal{
  color: #666666;
  font-size: 12px;
  font-family: 'Nunito-Regular';
}

.div-frequent-places{
  display: flex;
  position: relative;
  width:90%;
  height: 400px;
  margin-bottom: 10px;
  overflow-y: auto;
  padding-top: 20px;
  flex-direction: column;
}

.div-frequent-places-blur{
  /* background-color: rgba(255, 255, 255, 0.2); */
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 120%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.1); 
  backdrop-filter: blur(2px); 
}

.title-frequent-places{
  font-family: 'Nunito-Bold';
  font-size: 15px;
  color: #000000;
}

.div-frequent-places-content{
  /* background-color: #1e7899; */
  display: flex;
  width: 100%;
  height: 85%;
  margin-bottom: 10px;
  overflow-y: auto;
  margin-top: 20px;
}

.div-frequent-places-side-haeder {
  /* background-color: #99931e; */
  display: flex;
  flex-direction: row;
  /* align-items: center; */
}

.text-requent-places-side-haeder {
  font-family: 'Nunito-Regular';
  font-size: 13px;
  color: #000000;
  margin-left: 5px;
  margin-bottom: 15px;
  height: 100%;
}

.div-left-frequent-places-content{
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 100%;
  /* padding-top: 20px; */
}

.text-frequent-places {
  font-family: 'Nunito-Regular';
  font-size: 13px;
  color: #00688D;
  margin-bottom: 10px;
  cursor: pointer;
}

.text-frequent-places-top {
  font-family: 'Nunito-Bold';
  font-size: 13px;
  color: #00688D;
  margin-bottom: 10px;
  cursor: pointer;
}

.div-right-frequent-places-content{
  /* background-color: #991e1e; */
  flex-direction: column;
  display: flex;
  width: 50%;
  height: 100%;
  padding-left: 10%;
  /* padding-top: 20px; */
}


@media (max-width: 1080px) {
  .div-title-content {
    /* background-color: #1D212B; */
    display: flex;
    height: 30%;
    width: 90%;
    justify-content: center;
    align-items: center;
    margin-top: 0px;
    margin-bottom: 0px;
    z-index: 3;
  }
}

@media (max-width: 1054px) {
  .div-content{
    display: flex;
    /* background-color: #1D212B; */
    /* background: linear-gradient(to bottom, #2a2a2a, #00688D, #ffffff); */ 
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 500px;
    padding-top: 30px;
  }


  .div-passengers {
    background-color: rgb(255, 255, 255);
    display: flex;
    width: 48%;
    border-radius: 10px;
    height: auto;
  }
  
  .div-switch-trip-passengers {
    display: flex;
    width: 100%;
    height: auto;
    min-width: fit-content;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .div-switch-trip {
    display: flex;
    width: 48%;
  }

  .div-title-content {
    /* background-color: #1D212B; */
    display: flex;
    height: 30%;
    width: 90%;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
    z-index: 3;
  }
  
  .title-content {
    /* background-color: #1D212B; */
    display: flex;
    color: aliceblue;
    font-family: 'Nunito-Bold';
    font-size: 22px;
    /* margin-bottom: 30px; */
    text-transform: uppercase;
    text-align: center;
  }

  .div-inputs-date-type-trip {
    position: relative;
    /* background-color: #13b819; */
    display: flex;
    flex-direction: column;
    width: 100%;
    /* min-width: 550px; */
    justify-content: space-between;
    align-items: center;
    height: auto;
  }

  .div-inputs-places {
    background-color: #ffffff;
    /* flex: 1; */
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    justify-content: center;
    width: 100%;
    height: 110px;
  }

  .div-inputs-places-separator {
    display: flex;
    background-color: #EEEEEE;
    width: 100%;
    height: 2px;
  }

  .div-inputs-search{
    /* background-color: #13b819; */
    display: flex;
    width: 90%;
    justify-content: space-between;
    align-items: center;
    height:  60%;
    min-width: auto;
    flex-direction: column;
    margin-top: 0px;
  }

  .div-inputs-places-quantify {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    /* min-width: 500px; */
  }

  .div-quantify {
    /* background-color: #13b819; */
    display: flex;
    flex-direction: row;
    width: 98%;
    align-items: center;
    height:  auto;
    justify-content: space-between;
    border: 1px solid #ffffff;  
    margin-top: 10px;
    margin-bottom: 5px;
    border-radius: 5px;
    align-items: center;
  }
  
  .div-adults-quantify{
    /* background-color: #0e1572; */
    display: flex;
    width: 30%;
    /* justify-content: space-between; */
    align-items: center;
    height:  auto;
    /* border: 1px solid #ffffff;  */
    justify-content: center; 
    border-radius: 7px;
    /* margin-right: 10px; */
    /* margin-top: 10px; */
  }
  
  .text-adults-quantify {
    color: rgb(244, 244, 244);
    font-size: 14px;
    font-family: 'Nunito-Bold';
    margin-left: 5px;  
    margin-right: 5px;
  }

 
  .div-inputs-date {
    /* background-color: #13b819; */
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    height:  fit-content;
    /* margin-top: 10px */
  }

  .div-search-button{
    display: flex;
    width: 100%;
    height:  20%;
    margin-left: 0px;
    z-index: 3;
  }

  .div-background-footer-modal {
    /* background-color: #ffffff; */
    background-color: #3636368f;
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0%; 
    left: 0;
    overflow: hidden;
    z-index: 1000; 
    transform: scale(1); 
  }

  .div-search-footer-modal-place{
    /* background-color: #1e5799; */
    display: flex;
    width: 100%;
    /* height: 100vh; */
    max-height:  calc(100dvh - 100px);
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .div-input-search-footer-modal-place{
    /* background-color: #1e7899; */
    display: flex;
    width: 90%;
    height: 50px;
    margin-top: 10px;
    /* justify-content: center; */
  }

  .div-frequent-places{
    display: flex;
    position: relative;
    width: 80%;
    height: 100vh;
    margin-bottom: 10px;
    overflow-y: auto;
    padding-top: 20px;
    flex-direction: column;
  }

  .div-frequent-places-blur{
    /* background-color: rgba(255, 255, 255, 0.2); */
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    width: 120%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.1); 
    backdrop-filter: blur(2px); 
  }

  .title-frequent-places{
    font-family: 'Nunito-Bold';
    font-size: 15px;
    color: #000000;
  }

  .div-frequent-places-content{
    /* background-color: #1e7899; */
    display: flex;
    width: 100%;
    height: 85%;
    margin-bottom: 10px;
    overflow-y: auto;
    margin-top: 20px;
  }
  
  .div-frequent-places-side-haeder {
    /* background-color: #99931e; */
    display: flex;
    flex-direction: row;
    /* align-items: center; */
  }

  .text-requent-places-side-haeder {
    font-family: 'Nunito-Regular';
    font-size: 13px;
    color: #000000;
    margin-left: 5px;
    margin-bottom: 15px;
    height: 100%;
  }

  .div-left-frequent-places-content{
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 100%;
    /* padding-top: 20px; */
  }

  .text-frequent-places {
    font-family: 'Nunito-Regular';
    font-size: 13px;
    color: #00688D;
    margin-bottom: 10px;

    :hover{
      cursor: pointer;
    }
  }

  .div-right-frequent-places-content{
    /* background-color: #991e1e; */
    flex-direction: column;
    display: flex;
    width: 50%;
    height: 100%;
    padding-left: 10%;
    /* padding-top: 20px; */
  }

  .div-content-footer-passengers-selector-modal{
    /* background-color: #1e5799; */
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    flex-direction: column;
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .div-passengers-item-selector-modal{
    /* background-color: #68991e; */
    display: flex;
    flex-direction: row;
    width: 85%;
    height: fit-content;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 0px;
  }

  .div-label-passengers-item-selector-modal{
    /* background-color: #1e5799; */
    display: flex;
    flex-direction: column;
    height: auto;
    justify-content: space-between;
  }

  .tille-passengers-item-selector-modal{
    color: #838383;
    font-size: 17px;
    font-family: 'Nunito-Bold';
  }

  .description-passengers-item-selector-modal{
    color: #666666;
    font-size: 12px;
    font-family: 'Nunito-Regular';
  }
}

@media (max-width: 580px) {
  /* .div-inputs-places-quantify {
    display: flex;
    flex-direction: column;
    width: 100%;
  } */

  .div-title-content {
    /* background-color: #1D212B; */
    display: flex;
    height: 30%;
    width: 98%;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
    z-index: 3;
  }
  
  .title-content {
    /* background-color: #1D212B; */
    display: flex;
    color: aliceblue;
    font-family: 'Nunito-Bold';
    font-size: 18px;
    /* margin-bottom: 30px; */
    text-transform: uppercase;
    text-align: center;
  }

  .div-quantify {
    /* background-color: #13b819; */
    display: flex;
    flex-direction: row;
    width: 98%;
    align-items: center;
    height:  auto;
    justify-content: space-between;
    border: 1px solid #ffffff;  
    margin-top: 10px;
    margin-bottom: 5px;
    border-radius: 5px;
    align-items: center;
  }
  
  .div-adults-quantify{
    /* background-color: #0fa2d8; */
    display: flex;
    flex-direction: column;
    width: 30%;
    /* justify-content: space-between; */
    align-items: center;
    height:  auto;
    /* border: 1px solid #ffffff;  */
    justify-content: center; 
    border-radius: 7px;
    padding-top: 5px;
    padding-bottom: 5px;
    /* margin-right: 10px; */
    /* margin-top: 10px; */
  }
  
  .text-adults-quantify {
    color: rgb(244, 244, 244);
    font-size: 14px;
    font-family: 'Nunito-Bold';
    margin-left: 5px;  
    margin-right: 5px;
  }

  .div-background-footer-modal {
    /* background-color: #ffffff; */
    background-color: #3636368f;
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0%; 
    left: 0;
    overflow: hidden;
    z-index: 1000;
    transform: scale(1); 

  }
}

@media (max-width: 440px) {
  .div-content{
    display: flex;
    /* background-color: #1D212B; */
    /* background: linear-gradient(to bottom, #2a2a2a, #00688D, #ffffff); */ 
    width: 100%;
    justify-content: center;
    align-items: center;
    height: fit-content;
    padding-top: 0px;
  }
  .div-title-content {
    /* background-color: #1D212B; */
    display: flex;
    height:fit-content;
    width: 90%;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
    z-index: 3;
  }
  
  .title-content {
    /* background-color: #1D212B; */
    display: flex;
    color: aliceblue;
    font-family: 'Nunito-Bold';
    font-size: 20px;
    /* margin-bottom: 30px; */
    text-transform: uppercase;
    text-align: center;
  }

  .div-inputs-search{
    /* background-color: #13b819; */
    display: flex;
    width: 90%;
    justify-content: space-between;
    align-items: center;
    height:  60%;
    min-width: auto;
    flex-direction: column;
    margin-top: 0px;
  }

  .div-inputs-date {
    /* background-color: #13b819; */
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    height: fit-content;
  }

  .div-search-button{
    display: flex;
    width: 100%;
    height: 60px;
    margin-left: 0px;
    z-index: 3;
  }
}

@media (max-width: 439px) {
 .div-content{
    display: flex;
    /* background-color: #1D212B; */
    /* background: linear-gradient(to bottom, #2a2a2a, #00688D, #ffffff); */ 
    width: 100%;
    justify-content: space-between;
    align-items: center;
    height:  fit-content;
    padding-top: 0px;
    padding-bottom: 0px;
  }
}